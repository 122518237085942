@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anybody:wght@500;600&display=swap");
:root {
  --primaryColor: #ed1c24;
  --textColor: #2e2e2e;
}
.loader {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  font-family: "Roboto", sans-serif;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root {
  overflow-x: hidden;
}
.btn {
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  background-color: var(--primaryColor);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 200ms;
}
.btn.big {
  padding: 20px 30px;
  font-size: 18px;
}
.btn:hover {
  background-color: #b61127;
  cursor: pointer;
}
a {
  text-decoration: none;
}
.subheader {
  width: 100vw;
  background-color: rgb(240, 240, 240);
  border-top: 1px solid rgb(122, 122, 122);
  border-bottom: 1px solid rgb(122, 122, 122);
  padding: 20px 0;
}
.subheaderInner {
  padding-left: 15px;
  font-size: 24px;
  max-width: 1200px;
  margin: 0 auto;
}
div.hide {
  display: none;
}

/* MINI HEADER SECTION */
.miniHeader {
  z-index: 4;
  height: 50px;
  width: 100%;
  background-color: var(--textColor);
  position: fixed;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.miniHeaderInner {
  height: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.miniHeaderInner .container {
  display: flex;
  align-items: center;
}
.miniHeader svg {
  margin-right: 10px;
}
.miniHeader a {
  margin: 0 15px;
}
.miniHeader .counter {
  padding-left: 10px;
  font-weight: bold;
}
.miniHeader .phone {
  margin-left: 20px;
  font-size: 12px;
  opacity: 0.6;
  color: white;
  text-decoration: underline;
}
.miniHeader .shipping {
  margin: 0 20px;
  font-size: 12px;
  opacity: 0.6;
  color: white;
  display: flex;
  align-items: center;
}
.miniHeader .email {
  margin-left: 12px;
  font-size: 12px;
  opacity: 0.6;
  color: white;
  text-decoration: underline;
}

/* NAVBAR STYLES */
header {
  margin-top: 50px;
  background-color: #ed1c24;
  height: 90px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.navbarInner {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.navbarInner-links {
  display: flex;
  margin-left: 20px;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}
.navbarInner-links ul {
  display: flex;
}
.navbarInner-links ul li {
  list-style-type: none;
  margin: 0 10px;
  display: flex;
  align-items: flex-end;
}
.navbarInner-links ul li.active {
  border-bottom: 6px solid white;
  padding-bottom: 12px;
}
.navbarInner-links ul li:not(.active) {
  padding-bottom: 18px;
}
.navbarInner-links ul li a:hover {
  color: #e7c2c4;
}
.navbarInner-links ul li a {
  color: white;
  text-decoration: none;
  font-weight: normal;
  font-size: 21px;
  letter-spacing: -1.3px;
  transition: 200ms;
}
.navbarInner-links-social {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 20px;
}
.navbarInner-links-social a {
  margin: 10px;
}
.navbarInner-links-social a svg {
  transition: 200ms;
  fill: white;
}
.navbarInner-links-social a svg:hover {
  fill: #e7c2c4;
}
.navbarInner-logo {
  display: flex;
  align-items: center;
  width: 250px;
}
.mobile-menu {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 200vw;
  width: 100vw;
  height: 100vh;
  background-color: var(--textColor);
  transition: 300ms;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  opacity: 0;
}
.mobile-menu li {
  list-style-type: none;
  text-align: center;
  margin: 40px 0;
}
.mobile-menu-slide {
  left: 0;
  opacity: 1;
}
.escape-btn svg {
  position: absolute;
  top: 75px;
  right: 25px;
  margin: 0;
}
.mobile-menu-social a {
  margin: 10px;
}
.mobile-menu-route a {
  color: #fafafa;
  text-decoration: none;
  font-weight: 700;
  font-size: 26px;
  letter-spacing: -1.3px;
}
.mobile-menu-route.active a {
  color: var(--primaryColor);
}

/* HERO STYLES */
.hero {
  height: 85vh;
  width: 100%;
  /* background: rgb(255,145,150);
  background: radial-gradient(circle, rgba(255,145,150,1) 0%, rgba(237,28,36,1) 54%, rgba(237,28,36,1) 100%); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: center;
  background-position-x: right;
}
.heroInner {
  max-width: 1200px;
  height: 100%;
  margin: 0 auto;
  padding-left: 20px;
  height: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(6, 1fr);
}
.heroInner .left {
  grid-column: span 4;
}
.heroInner .right {
  grid-column: span 2;
  height: 100%;
  justify-self: center;
  display: flex;
  align-items: center;
}
.heroInner .right img {
  height: 70vh;
}
@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-40px);
  }
  100% {
    transform: translatey(0px);
  }
}

.heroInner p {
  width: 600px;
  max-width: 90%;
  color: #ffffff;
  opacity: 0.8;
  line-height: 1.4;
  padding-top: 10px;
}
.heroInner h1 {
  font-family: "Anybody", sans-serif;
  font-size: 66px;
  color: #fafafa;
  padding: 5px 0;
  position: relative;
  overflow: hidden;
  text-shadow: 2px 2px 11px #000000;
}

.heroInner h1::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: white;
  animation: a-ltr-after 0.3s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(-101%);
}
.heroInner h1::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #7a7a7a;
  animation: a-ltr-before 0.3s cubic-bezier(0.77, 0, 0.18, 1) forwards;
  transform: translateX(0);
}
.heroInner h1:nth-of-type(1)::before,
.heroInner h1:nth-of-type(1)::after {
  animation-delay: 0.3s;
}
.heroInner h1:nth-of-type(2)::before,
.heroInner h1:nth-of-type(2)::after {
  animation-delay: 0.6s;
}
@keyframes a-ltr-after {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(101%);
  }
}
@keyframes a-ltr-before {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
  }
}
.heroInner button {
  position: relative;
  top: 30px;
}

/* PROFILE PAGE */
.profileContainer {
  width: 100%;
  background: rgb(250, 250, 250);
  background: linear-gradient(
    360deg,
    rgba(250, 250, 250, 1) 0%,
    rgb(212, 220, 255) 100%
  );
}
.profileInner {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.profile-login,
.profile-signup {
  width: 60%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid rgb(223, 223, 223);
  margin: 20px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.profileInner h3 {
  margin-bottom: 20px;
}
.profileInner p {
  width: 70%;
  text-align: center;
  margin: 10px 0;
}
.profile-signup-group,
.profile-login-group {
  width: 100%;
  display: flex;
  justify-content: center;
}
.profile-signup-group div:first-child,
.profile-login-group div:first-child {
  margin-right: 10px;
}
.fizickoLice,
.pravnoLice {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.userIsLoggedIn {
  background: rgb(250, 250, 250);
  background: linear-gradient(
    360deg,
    rgba(250, 250, 250, 1) 0%,
    rgb(212, 220, 255) 100%
  );
}
.userIsLoggedInInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20vh;
  padding-left: 10px;
  padding-right: 10px;
}
.userIsLoggedInInner h1 {
  margin: 20px 0;
}
.userIsLoggedInInner h3 {
  margin: 10px 0;
}
.userIsLoggedInInner button {
  margin-left: 20px;
}

/* FEATURED PRODUCTS STYLES */
.featuredProducts {
  background-color: rgb(233, 233, 233);
}
.featuredProducts h1 {
  text-align: center;
  margin: 20px 0 40px 0;
}
.featuredProductsInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px;
}
.featuredProducts .cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

/* PRODUCT CARD STYLES */
.productCard {
  border: 1px solid gainsboro;
  background-color: white;
  width: 100%;
  position: relative;
  padding: 20px;
  padding-bottom: 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  transition: 200ms;
  overflow: hidden;
}
.productCard.specijal {
  border: 3px solid gold;
  background-color: rgb(255, 251, 231);
}
.productCard .name {
  color: #2e2e2e;
  text-align: center;
}
.productCard:hover .name {
  color: #ed1c24;
}
.productCard:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.productCard-description {
  padding: 10px 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.productCard-description .price {
  display: flex;
  font-size: 30px;
}
.productCard-description .price .right {
  display: flex;
  justify-content: center;
  padding-left: 3px;
  flex-direction: column;
}
.productCard-description .price .right span:first-child {
  font-size: 15px;
  line-height: 0.9;
}
.productCard-description .price .right span:nth-child(2) {
  font-size: 12px;
  line-height: 0.9;
}
.productCard-image {
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(219, 219, 219);
}
.productCard-image img {
  height: 100%;
  width: 100%;
}
.productCard button {
  align-self: flex-end;
}
.productCard .name {
  font-weight: normal;
  transition: 200ms;
}
.productCard .labelImage {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50%;
  height: auto;
}
.badgeBackground {
  width: 0;
  height: 0;
  top: -1px;
  left: -1px;
  transform: rotate(-90deg);
  position: absolute;
  transition: 200ms;
}
.badgeBackground.akcija {
  border-top: 120px solid rgb(255, 66, 66);
  border-left: 120px solid rgba(255, 255, 0, 0);
}
.badgeBackground.znacka {
  border-top: 120px solid #23b84b;
  border-left: 120px solid rgba(255, 255, 0, 0);
}
.badgeText {
  position: absolute;
  text-align: center;
  height: 120px;
  width: 120px;
  top: 0;
  left: 0;
  transform: rotate(-45deg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 65px;
  font-weight: bold;
  color: rgb(255, 255, 255);
  font-size: 21px;
}
.productCard.specijal .badgeText {
  padding-bottom: 30px;
  font-size: 16px;
}
.badgeText.znacka {
  font-size: 16px;
}
.badgeText p:nth-child(2) {
  text-decoration: line-through;
  font-weight: normal;
  font-size: 15px;
}
.productCard:hover .badgeBackground {
  /* top: -9px; left: -9px; */
  border-top-width: 130px;
  border-left-width: 130px;
}
.badgeBackground:hover {
  cursor: pointer;
}
.badgeText:hover {
  cursor: pointer;
}
.productCard:hover .price {
  color: #ed1c24;
}

/* NEWS STYLES */
.news {
  background-color: rgb(219, 219, 219);
}
.newsInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.news h1 {
  font-size: 24px;
  text-align: center;
  margin: 20px 0 40px 0;
}
.news .img {
  width: 100%;
}

/* FOOTER STYLES */
.footer {
  background: rgb(15, 15, 15);
  background: linear-gradient(0deg, rgba(15, 15, 15, 1) 0%, #270505 100%);
  color: #fafafa;
}
.footerInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.footer .bottom {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer .copyrigth {
  font-size: 10px;
}
.footer .social {
  width: 120px;
  display: flex;
  justify-content: space-between;
}
.footer .social svg {
  fill: white;
}
.newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.newsletter p {
  opacity: 0.7;
  text-align: center;
  margin-bottom: 20px;
}
.links {
  padding: 40px 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: center;
}
.links a:after {
  content: "";
  width: 0px;
  height: 2px;
  display: block;
  background: rgb(255, 255, 255);
  transition: 300ms;
}
.links a:hover:after {
  width: 100%;
}
.links .kupovina,
.links .oNama {
  display: flex;
  flex-direction: column;
  margin: 0 5%;
}
.links .kupovina h2,
.links .oNama h2 {
  text-align: center;
  margin-bottom: 10px;
}
.links .items {
  display: flex;
  flex-direction: column;
}
.links .items a {
  text-align: center;
  color: #fafafa;
  text-decoration: none;
  font-size: 22px;
  letter-spacing: -1px;
  transition: 200ms;
  opacity: 0.7;
}
.links .items a:hover {
  opacity: 1;
}

/* ALL PRODUCTS STYLES allProducts */
.allProductsFilter {
  background-color: whitesmoke;
}
.allProductsFilterInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}
.filter .buttons {
  display: flex;
  flex-wrap: wrap;
}
.filter h2 {
  padding-left: 10px;
  margin: 10px 0;
}
.filter button {
  margin: 5px 10px;
}

/* CONTACT STYLES */
.contact {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contactInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
  align-items: center;
}
.contactInfo,
.message {
  margin: 20px 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 60%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.message h3,
.contactInfo h3 {
  text-decoration: underline;
  margin-bottom: 40px;
  text-align: center;
}
.contactInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contactInfo p {
  font-size: 12px;
  display: flex;
  align-items: center;
  opacity: 0.6;
}
.contactInfo p svg {
  margin-right: 6px;
}
.contactInfo a {
  text-decoration: underline;
  color: rgb(0, 0, 0);
  font-size: 21px;
  margin-bottom: 20px;
  text-align: center;
}
.contactInfo a.noMargin {
  margin: 0;
}
.message .input {
  margin: 15px 0;
  width: 100%;
}
.message button {
  margin-top: 15px;
}

/* CART STYLES  */
.cart {
  background-color: #fafafa;
  width: 100%;
  min-height: 60vh;
  background: rgb(250, 250, 250);
  background: linear-gradient(
    360deg,
    rgba(250, 250, 250, 1) 0%,
    rgb(212, 220, 255) 100%
  );
}
.cartInner {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

/* PRODUCT CONFIRM STEP STYLES */
.productConfirm .listItemMobile {
  display: none;
  background-color: #fafafa;
}
.productConfirm h2 {
  margin: 20px 0;
}
.productConfirm .gridList {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.productConfirm .cancel {
  transition: 300ms;
}
.productConfirm svg.cancel:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.productConfirm .placeholder,
.productConfirm .listItem {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}
.productConfirm .listItem {
  background-color: #fafafa;
  border: 1px solid rgb(209, 209, 209);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 10px 0;
  border-radius: 5px;
}
.productConfirm .listItem button,
.productConfirm .listItemMobile button {
  border-radius: 100%;
  border: none;
  background-color: rgb(204, 204, 204);
  display: flex;
  align-items: center;
  padding: 6px;
}
.productConfirm .listItem button:hover {
  cursor: pointer;
}
.productConfirm .placeholder span,
.productConfirm .listItem span {
  display: flex;
  justify-content: center;
  align-items: center;
}
.productConfirm img {
  height: 90px;
}
.productConfirm .placeholder span:nth-child(1),
.productConfirm .listItem span:nth-child(1) {
  min-width: 90px;
}
.productConfirm .placeholder span:nth-child(2),
.productConfirm .listItem span:nth-child(2) {
  width: 20%;
}
.productConfirm .placeholder span:nth-child(3),
.productConfirm .listItem span:nth-child(3) {
  width: 10%;
}
.productConfirm .placeholder span:nth-child(4),
.productConfirm .listItem span:nth-child(4) {
  width: 20%;
}
.productConfirm .placeholder span:nth-child(5),
.productConfirm .listItem span:nth-child(5) {
  width: 10%;
}
.productConfirm .placeholder span:nth-child(6),
.productConfirm .listItem span:nth-child(6) {
  width: 5%;
}
.productConfirm .placeholder span {
  font-weight: bold;
}
.productConfirm .total {
  display: flex;
  flex-direction: column;
  background-color: rgb(224, 224, 224);
  width: 50%;
  margin-left: 50%;
  margin-top: 20px;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 5px;
}
.productConfirm .total .freeDelivery {
  width: 100%;
  border-radius: 5px 5px 0 0;
  background-color: #aebaff;
  font-weight: bold;
  text-align: center;
  padding: 10px 0;
}
.productConfirm .total .prices {
  padding: 15px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.productConfirm .total .big {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.productConfirm .total .big p {
  color: #ed1c24;
  font-weight: bold;
  font-size: 24px;
}
.productConfirm .listItemMobile {
  display: none;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 20px 0;
  font-size: 14px;
  position: relative;
  padding-right: 20px;
  padding: 10px 20px 10px 0;
  border-radius: 5px;
}
.productConfirm .listItemMobile img {
  height: 20vh;
  border-radius: 5px 0 0 5px;
}
.productConfirm .listItemMobile .text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 10px;
  flex-grow: 1;
}
.productConfirm .listItem .plus,
.productConfirm .listItem .minus {
  transition: 300ms;
}
.productConfirm .listItem .plus:hover {
  background-color: green;
}
.productConfirm .listItem .plus:hover svg {
  fill: white;
}
.productConfirm .listItem .minus:hover {
  background-color: red;
}
.productConfirm .listItem .minus:hover svg {
  fill: white;
}
.listItemMobile .text span:first-child {
  font-weight: bold;
  font-size: 16px;
}
.listItemMobile .text .counter {
  display: flex;
  align-items: center;
}
.listItemMobile .text .counter span {
  margin: 0 10px;
}
.listItemMobile .cancelContainer {
  position: absolute;
  right: 0;
  top: 0;
}
.listItemMobile .cancelContainer svg {
  height: 20px;
  width: 20px;
}
.listItemMobileContainer hr {
  display: none;
}

.socialPosts {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.socialPostsContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-items: center;
  grid-gap: 20px;
}
.socialPostsContainer iframe {
  max-width: 100%;
}
.socialPosts h2 {
  text-align: center;
  padding: 40px 0;
}

.mobileContact {
  position: fixed;
  right: 60px;
  bottom: 60px;
  z-index: 999;
}
.mobileContact a {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: white;
  background-color: rgb(195, 144, 23);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 500ms;
}
.mobileContact a:last-child {
  top: -1px;
  left: -1px;
  width: 52px;
  height: 52px;
}
.mobileContact .phone {
  background-color: green;
}
.mobileContact .facebook {
  background-color: #1b74e4;
}
.mobileContact .mail {
  background-color: rgb(59, 82, 19);
}
.mobileContact .facebook.open {
  top: -120px;
}
.mobileContact .phone.open {
  top: -60px;
}
.mobileContact .mail.open {
  top: -180px;
}

.mobile {
  display: none;
}
/* mobile  */
@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: unset;
  }
  .heroInner .right {
    display: none;
  }

  .mob-width-100 {
    width: 100%;
  }
  .subheaderInner {
    text-align: center;
  }
  .miniHeaderInner .info {
    display: none;
  }
  .miniHeader .container {
    justify-content: space-around;
    width: 100%;
  }
  header {
    height: 70px;
  }
  .navbarInner {
    height: unset;
    width: 100%;
    justify-content: space-between;
  }
  .navbarInner-links {
    margin-left: 0;
    height: 70px;
  }
  .navbarInner-logo {
    width: 180px;
  }
  .navbarInner-logo img {
    height: 70px;
  }
  .navbarInner-links-router {
    height: 100%;
    align-items: center;
  }
  .navbarInner-links-router-mobile {
    height: 100%;
    display: flex;
    align-items: center;
    color: var(--textColor);
    transition: 200ms;
  }
  .navbarInner-links-router-mobile svg {
    margin: 0 10px 0 15px;
  }
  .navbarInner-links-router-mobile:hover {
    color: var(--primaryColor);
  }
  .navbarInner-links-router-mobile span {
    font-weight: 700;
    font-size: 26px;
    letter-spacing: -1.3px;
  }
  .heroInner {
    align-items: center;
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
  .heroInner .left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .heroInner h1 {
    font-size: 35px;
    text-align: center;
  }
  .btn.big {
    padding: 10px 20px;
  }
  .heroInner p {
    text-align: center;
  }
  .profile-login,
  .profile-signup {
    width: 90%;
  }
  .profile-signup-group,
  .profile-login-group {
    flex-direction: column;
    align-items: center;
  }
  .profile-signup-group div:first-child,
  .profile-login-group div:first-child {
    margin-right: 0;
  }
  .userIsLoggedInInner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .contactInfo,
  .message {
    width: 100%;
  }
  .stepLabel span.MuiStepLabel-label {
    display: none;
  }
  .productConfirm .total {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
  .productConfirm .listItemMobile {
    display: flex;
  }
  .productConfirm .listItem,
  .productConfirm .placeholder {
    display: none;
  }

  .featuredProducts .cardsContainer {
    grid-template-columns: 1fr;
  }
  .socialPostsContainer {
    grid-template-columns: 1fr;
  }
  .productConfirm .total .prices {
    display: flex;
    flex-direction: column;
  }
  .productConfirm .total .big {
    padding-top: 20px;
  }
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}
button.pulse {
  box-shadow: 0 0 0 0 rgba(32, 150, 32, 0.7);
  -webkit-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  -ms-animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulsing 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}
@-webkit-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(61, 232, 98, 0);
  }
}
@-moz-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(61, 76, 61, 0);
  }
}
@-ms-keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(61, 76, 61, 0);
  }
}
@keyframes pulsing {
  to {
    box-shadow: 0 0 0 30px rgba(61, 76, 61, 0);
  }
}
