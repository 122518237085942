.singleProduct .breadcrumb {
  border-bottom: 1px solid gainsboro;
}
.breadcrumbInner {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
  display: flex;
  align-items: center;
}
.singleProductInner {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.singleProductInner.specijal {
  background-color: rgb(255, 251, 231);
}
.singleProduct .img {
  position: relative;
  margin-right: 20px;
}
.singleProduct .img .imgContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
}
.singleProduct .img img {
  max-height: 60vh;
}
.singleProduct .text i {
  opacity: 0.6;
}
.singleProduct .text .price {
  padding: 20px 0 10px 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  letter-spacing: -1px;
  color: rgb(37, 37, 37);
}
.singleProduct .text .price .big {
  font-size: 51px;
}
.singleProduct .text .price .small {
  padding-left: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 21px;
  line-height: 1;
}
.singleProduct .text .note {
  opacity: 0.6;
  color: green;
}
.singleProduct p.akcija {
  color: red;
  opacity: 0.6;
  margin: 5px 0;
}
.singleProduct .text .counter {
  margin: 20px 0;
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px solid gainsboro;
  background-color: white;
  width: 170px;
  overflow: hidden;
}
.singleProduct .text .counter button {
  border: none;
  width: 44px;
}
.singleProduct .text .counter .plus {
  border-left: 1px solid gainsboro;
  display: flex;
  align-items: center;
  padding: 10px;
  transition: 300ms;
}
.singleProduct .text .counter .minus {
  border-right: 1px solid gainsboro;
  display: flex;
  align-items: center;
  flex-shrink: 1;
  padding: 10px;
  transition: 300ms;
}
.singleProduct .text .counter p {
  text-align: center;
  flex-grow: 1;
}
.singleProduct .text .counter .plus:hover {
  background-color: green;
  cursor: pointer;
}
.singleProduct .text .counter .plus:hover svg {
  fill: white;
}
.singleProduct .text .counter .minus:hover {
  background-color: red;
  cursor: pointer;
}
.singleProduct .text .counter .minus:hover svg {
  fill: white;
}
.singleProduct .text .description {
  margin: 30px 0;
  opacity: 0.9;
}
.singleProduct .text .roast {
  margin: 40px 0;
}
.singleProduct .text .roast .gradient {
  margin-top: 25px;
  background: rgb(232, 176, 63);
  background: linear-gradient(
    90deg,
    rgba(232, 176, 63, 1) 0%,
    rgb(78, 48, 2) 100%
  );
  width: 0;
  height: 25px;
  border: 1px solid gainsboro;
  position: relative;
  box-sizing: content-box;
  animation: gradientAnime 1s 500ms ease-in-out forwards;
}
.singleProduct .text .roast .line {
  background-color: rgb(46, 46, 46);
  height: 30px;
  width: 2px;
  position: absolute;
  top: -3px;
}
.singleProduct .text .roast .cursor {
  position: absolute;
  padding-left: 2px;
  top: -39px;
  left: 10%;
  width: 20%;
  display: flex;
  justify-content: center;
}
.singleProduct .text .roast .cursor svg {
  width: 50px;
  height: 50px;
  fill: rgb(232, 176, 63);
}
.singleProduct .text .zero,
.singleProduct .text .five {
  width: 20px;
  text-align: center;
  position: absolute;
  top: 27px;
  opacity: 0;
  animation: popUp 500ms 500ms forwards;
}
.singleProduct .text .zero {
  left: -8px;
}
.singleProduct .text .five {
  left: 292px;
}
.singleProduct .origin {
  margin: 50px 0 30px 0;
}
.singleProduct .origin li {
  display: flex;
  align-items: center;
  padding: 6px 10px;
}
.singleProduct .origin li:first-child {
  margin-top: 10px;
}
.singleProduct .origin li img {
  height: 23px;
  margin-left: 10px;
  border-radius: 3px;
}
.singleProduct .suggested {
  grid-column: span 2;
}
.singleProduct .suggested h3 {
  opacity: 0.6;
  margin: 20px 0;
}
.singleProduct .suggestedContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
}
.singleProduct .labelImage {
  position: absolute;
  bottom: 10px;
  right: 10px;
  width: 50%;
  height: auto;
}

@keyframes popUp {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100%;
  }
}

@keyframes gradientAnime {
  0% {
    width: 0px;
  }
  100% {
    width: 300px;
  }
}

@media screen and (max-width: 1000px) {
  .singleProductInner {
    max-width: 1200px;
    padding: 20px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr;
    justify-items: center;
  }
  .singleProduct .text {
    justify-self: start;
  }
  .singleProduct .img {
    margin: 0;
  }
  .singleProduct .img img {
    max-width: 100%;
  }
  .singleProduct .text {
    width: 100%;
  }
  .singleProduct button {
    width: 100%;
  }
  .singleProduct .suggested {
    grid-column: span 1;
  }
  .singleProduct .suggestedContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
  }
  .singleProduct .suggestedContainer .productCard:last-child {
    display: none;
  }
}
@media screen and (max-width: 350px) {
  .singleProduct .text .roast .gradient {
    max-width: 250px;
  }
  .singleProduct .text .five {
    left: 242px;
  }
}
